<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'
import { validateMimeType } from '@/support/customValidators/mimeTypeValidator'

export default {
  name: 'EnrollmentBatchManage',
  directives: { mask },
  components: {
    FileField: () => import('@/components/general/FileField'),
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    // InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    // EmptyMessage: () => import('@/components/general/EmptyMessage'),
    Checkbox: () => import('@/components/general/Checkbox')
  },

  data () {
    return {
      formData: {
        file: null,
        solutionId: null,
        sessionId: null,
        fixedEnrollStatus: false
      },

      solutionsList: [],
      sessionsList: [],
      allowedMimeTypes: [
        'text/csv',
        'text/plain',
        'application/vnd.ms-excel',
        'application/csv',
        'application/x-csv',
        'text/comma-separated-values',
        'text/x-comma-separated-values',
        'text/tab-separated-values',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]
    }
  },

  computed: {
    exampleFile () {
      return process.env.VUE_APP_API_URL + '/examples/batch/matriculas.csv'
    },

    getSolutionSessionType () {
      const solutions = this.solutionsList.filter((solution) => {
        return solution.value === this.formData.solutionId
      })

      return solutions.length > 0 ? solutions[0].sessionType : null
    }
  },

  watch: {
    'formData.solutionId' () {
      if (this.getSolutionSessionType === 'closed') {
        this.getSolutionSessionList(this.formData.solutionId)
      } else {
        this.sessionsList = []
        this.formData.sessionId = null
      }
    }
  },

  validations: {
    formData: {
      file: {
        required,
        fileValidation: function (file) {
          return validateMimeType(file, this.allowedMimeTypes)
        }
      },

      solutionId: { required },
      sessionId: {
        required: requiredIf(function () {
          return this.getSolutionSessionType === 'closed'
        })
      }
    }
  },

  created () {
    this.getSolutionsList()
  },

  methods: {
    ...mapActions([
      'attemptCreateEnrollmentBatch',
      'setFetching',
      'setFeedback',
      'attemptGetSessionsList',
      'attemptGetSolutionsForComboBox'
    ]),

    submit () {
      this.createEnrollmentBatch()
    },

    createEnrollmentBatch () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.setFetching(true)

        this.attemptCreateEnrollmentBatch(this.formData).then(() => {
          this.setFeedback({ message: this.$t('community.enrollment:batch.register.success') })
          this.leave()
        }).catch((e) => {
          if (e.data.error.message === 'batch_error_file_already_processing') {
            this.setFeedback({ message: this.$t('global.batch.error.file_already_processing') })
          } else if (e.data.error.message === 'invalid_file_type') {
            this.setFeedback({ message: this.$t('global.validation.file') })
          } else {
            this.setFeedback({ message: this.$t('global.error') })
          }
        }).finally(() => {
          this.setFetching(false)
        })
      } else {
        this.showValidationErrors()
      }
    },

    showValidationErrors () {
      this.$nextTick(() => {
        this.setFeedback({ message: this.$t('users.manage:validation.error') })

        this.debounceEvent(() => {
          const hasErrorElements = document.querySelector('.has-error')
          const top = hasErrorElements.offsetTop - 50

          document.querySelector('.modal-blocker').scroll({
            top: top,
            behavior: 'smooth'
          })
        }, 500)
      })
    },

    getSolutionsList () {
      this.setFetching(true)

      this.attemptGetSolutionsForComboBox().then((response) => {
        response.data.forEach((solution) => {
          this.solutionsList.push({
            text: solution.name,
            value: solution.id,
            sessionType: solution.sessionType
          })
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getSolutionSessionList () {
      this.setFetching(true)

      this.attemptGetSessionsList({
        filter: {
          id_solution: this.formData.solutionId
        },
        order: { session_name: 'ASC' },
        limit: 9999
      }).then((response) => {
        response.data.forEach((session) => {
          this.sessionsList.push({
            text: session.name,
            value: session.id
          })
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    leave () {
      if (this.$route.params.origin === 'batch.list') {
        this.$router.push({ name: 'batch.list' })
      } else {
        this.$router.push({ name: 'users.index' })
      }
    }
  }
}
</script>

<template>
  <Modal
    :active="true"
    :cancel-label="$route.params.origin === 'batch.list' ? $t('global:back') : ''"
    @close="leave"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('global:enrollment') }}</span>
      <h2 class="modal-title">
        {{ $t('user.enrollment.index:modal.title.batch.add') }}
      </h2>
      <div class="wrapper">
        <Action
          :text="$t('global:download.example')"
          type="link"
          :url="exampleFile"
          class="btn-export"
          primary
          dark
          download
        />
      </div>
      <form @submit.prevent="submit()">
        <FormSection :title="$t('global:form.filters')">
          <SelectField
            v-if="solutionsList.length"
            v-model="formData.solutionId"
            :label="$t('global:form.solution')"
            :items="solutionsList"
            :validation="$v.formData.solutionId"
            dark
          />
          <SelectField
            v-if="sessionsList.length"
            v-model="formData.sessionId"
            :label="$t('global:form.session')"
            :items="sessionsList"
            :validation="$v.formData.sessionId"
            dark
          />
          <FileField
            v-model="formData.file"
            :label="$t('global:upload.add.file')"
            :validation="$v.formData.file"
            :accept="allowedMimeTypes.join()"
            dark
          />
          <Checkbox
            v-model="formData.fixedEnrollStatus"
            :items="[{label: $t('user.enrollment.index:modal.batch.fixed.enroll.status'), value: true}]"
            dark
          />
        </FormSection>

        <div class="form-submit text-center">
          <Action
            :text="$t('global:register')"
            type="button"
            secondary
            large
            submit
            fixed-width
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style>
  .profiles-selection {
    margin-top: 60px;
  }
  .wrapper {
    display:flex;
    justify-content: center;
    width:100%;
    margin-top: 20px;
  }
</style>
